<template>
  <section>
    <!-- <v-row> -->
      <!-- <v-col v-if="$can('delete', 'analytics')">
        <v-btn color="primary" to="/site/create">
          <v-icon left>mdi-plus-thick</v-icon>New Site
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-btn>
            <v-icon>apps</v-icon>
          </v-btn>
          <v-btn>
            <v-icon>list_alt</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col> -->

    <v-row>
      <v-col v-if="$can('create', 'sites')">
        <v-btn color="primary" to="/site/create">
          <v-icon left>mdi-plus-thick</v-icon>New Site
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-text-field v-model="search" label="Search" solo></v-text-field>
      </v-col>
    </v-row>

    <!-- </v-row> -->
    <v-progress-linear :active="loading" :indeterminate="loading" absolute top></v-progress-linear>
    <v-data-table :headers="headers" :items="sites" :search="search" :options="tableOptions" class="elevation-1">
      <template v-slot:[`item.name`]="{ item }">
        <td>
          <router-link :to="`/site/edit/${item.siteId}`">{{ item.name }}</router-link>
        </td>
      </template>

      <template slot="items" slot-scope="props">
        <td>{{ props.item.floors }}</td>
        <td>{{ props.item.occupants }}</td>
        <td>{{ props.item.squareFootage }}</td>
        <td>{{ props.item.kwhCost }}</td>
        <td>{{ props.item.latitude }}</td>
        <td>{{ props.item.longitude }}</td>
        <td>{{ props.item.startTime }}</td>
        <td>{{ props.item.endTime }}</td>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  async created() {
    let response = await this.$http.get("/v1/sites");
    this.sites = response.data.data;
    this.loading = false;
  },

  data() {
    return {
      loading: true,
      search: "",
      headers: [
        { text: "Site Name", value: "name" },
        { text: "Floors", value: "floors" },
        { text: "Occupants", value: "occupants" },
        { text: "Square Footage", value: "squareFootage" },
        { text: "kWh Cost", value: "kwhCost" },
        { text: "Latitude", value: "latitude" },
        { text: "Longitude", value: "longitude" },
        { text: "Start Time", value: "startTime" },
        { text: "End Time", value: "endTime" }
      ],
      tableOptions: {itemsPerPage: -1},
      sites: [],

      toggleView: 0
    };
  },
};
</script>
